import Image from 'next/image';
import React from 'react';

import Icon from '@/components/v3/Icon';

const Partner: React.FC = () => {
  return (
    <div className="bg-stone-500">
      <div className="container flex flex-col items-center px-0 md:px-40 w-full max-w-[1117px]">
        <h2 className="px-24 md:px-32 font-bold lg:font-semibold text-center text-display-0 heading-2">
          Comece sua integração com a Stone
        </h2>
        <div className="flex flex-col md:flex-row justify-between mt-32 w-full bg-transparent">
          <div className="flex flex-col flex-1 justify-center items-start p-40 lg:p-80 md:py-80 w-full bg-display-0 rounded-tl-[60px]">
            <div className="flex justify-center md:justify-start w-full">
              <Image
                src="site-stone/devCenter/partner-logo"
                width={228}
                height={94}
                alt="Logo do programa de parcerias da Stone"
              />
            </div>
            <p className="mt-24 w-full paragraph-18">
              Quer saber como as empresas parceiras da Stone estão
              revolucionando a vida de seus clientes?
            </p>
            <a
              href="https://partner.stone.com.br/"
              className="flex items-center mt-16 w-full font-medium text-stone-500 paragraph-18"
            >
              Conheça nosso programa de parcerias
              <Icon
                name="chevron-right"
                className="ml-4 w-16 h-16 text-stone-500 fill-current"
              />
            </a>
          </div>
          <div className="flex flex-1 justify-center items-center p-40 lg:p-80 md:py-80 bg-display-50 rounded-br-[60px]">
            <Image
              src="site-stone/devCenter/partner"
              className="w-full min-w-[300px] max-w-[400px] h-auto"
              width={400}
              height={236}
              alt="Imagem ilustrativa de uma equipe engenhosa pensando em soluções"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
