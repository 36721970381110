import Image from 'next/image';
import React from 'react';

import Icon from '@/components/v3/Icon';

const Community: React.FC = () => {
  return (
    <div className="bg-display-0">
      <div className="container flex flex-col items-center py-40 md:py-80 px-0 md:px-40 w-full">
        <div className="flex flex-col md:flex-row justify-center items-stretch lg:items-end">
          <div className="flex object-cover md:min-w-[332px] min-h-full">
            <Image
              src="site-stone/devCenter/lecture"
              className="w-full h-auto rounded-tl-[60px]"
              width={576}
              height={461}
              style={{ objectFit: 'cover' }}
              alt="Rapaz da Stone de pé com microfone na mão fazendo uma fala para um escritório cheio"
            />
          </div>
          <div className="flex flex-col justify-center p-24 md:p-32 w-full max-w-[576px] md:max-w-[432px] bg-display-50 md:rounded-tr-4xl rounded-br-4xl md:rounded-br-none">
            <Icon
              name="calendar"
              className="w-40 h-40 text-stone-500 fill-current"
            />
            <h4 className="mt-8 font-semibold heading-5 lg:heading-4">
              Eventos
            </h4>
            <p className="mt-8 paragraph-16 lg:paragraph-20">
              Participe dos nossos eventos com talks e desafios de
              desenvolvimento para acelerar a sua integração com nossa
              tecnologia.
            </p>
          </div>
        </div>
        <h4 className="md:p-40 py-32 px-24 w-full max-w-[576px] md:max-w-[1008px] font-semibold text-center text-display-0 bg-display-900 heading-4">
          Junte-se a + de 1.500 desenvolvedores em nossa comunidade
        </h4>
        <div className="flex flex-col md:flex-row justify-center items-stretch lg:items-start">
          <div className="flex flex-col justify-center p-24 md:p-32 w-full max-w-[576px] md:max-w-[432px] bg-display-50 md:rounded-bl-4xl">
            <Icon
              name="global"
              className="w-40 h-40 text-stone-500 fill-current"
            />
            <h4 className="mt-8 font-semibold heading-5 lg:heading-4">
              Partner Tech Community
            </h4>
            <p className="mt-8 paragraph-16 lg:paragraph-20">
              Acesse os grupos de discussão de desenvolvimento, fóruns de
              integração e de produto, e conecte-se com outros desenvolvedores
              em nossa comunidade de parceiros.
            </p>
          </div>
          <div className="flex object-cover md:min-w-[332px] min-h-full">
            <Image
              src="site-stone/devCenter/community"
              className="w-full h-auto rounded-br-[60px]"
              width={576}
              height={461}
              style={{ objectFit: 'cover' }}
              alt="Reunião de diversos colaboradores Stone com seus notebooks reunidos em mesas debatendo as tecnologias"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
