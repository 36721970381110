import Image from 'next/image';

import Icon from '@/components/v3/Icon';

export interface ImageProps {
  width: number;
  height: number;
  src: string;
}

type TCard = {
  bannerImage: ImageProps;
  logoImage: ImageProps;
  description: string;
  href: string;
};

const Card: React.FC<TCard> = ({
  bannerImage,
  logoImage,
  description,
  href,
}) => {
  return (
    <div className="flex flex-col items-center w-full md:min-w-[260px] max-w-[330px] md:max-w-[336px]">
      <div className="flex object-cover justify-center items-center w-full h-full">
        <Image
          src={bannerImage.src || ''}
          width={bannerImage.width || 0}
          height={bannerImage.height || 0}
          style={{ objectFit: 'cover' }}
          className="h-full"
          alt=""
        />
      </div>
      <div className="flex flex-col p-24 w-full h-full bg-display-0">
        <div className="flex items-center w-full h-60">
          <Image {...logoImage} alt="image" />
        </div>
        <p className="mt-16 paragraph-18">{description}</p>
        <a href={href} className="flex items-center mt-16">
          <p className="font-medium text-stone-500 paragraph-18">Saiba mais</p>
          <Icon
            name="chevron-right"
            className="ml-4 w-16 h-16 text-stone-500 fill-current"
          />
        </a>
      </div>
    </div>
  );
};

export default Card;
