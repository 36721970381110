import Image from 'next/image';
import React from 'react';

import Conciliacao from '@/assets/illustrations/devcenter/conciliacao.svg';
import Icon from '@/components/v3/Icon';

const OnlineWorld: React.FC = () => {
  return (
    <div className="bg-display-100">
      <div className="container flex flex-col items-center">
        <div className="mb-32 lg:mb-40 text-center">
          <h2 className="font-bold heading-2">Mundo Online</h2>

          <p className="mt-8 paragraph-16 lg:paragraph-20">
            Transforme sua empresa simplificando a forma de receber pagamentos
            digitais
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-32 xl:mx-7">
          <div className="flex flex-col lg:flex-row-reverse gap-32 justify-center py-32 lg:py-36 px-24 lg:px-56 md:max-w-[835px] bg-display-0 rounded border">
            <div className="flex flex-col justify-center max-w-[370px]">
              <Image
                src="site-stone/devCenter/pagarme-tela"
                width={370}
                height={223}
                className="w-full h-auto rounded border"
                alt="Gráficos produtos da Pagarme"
              />
            </div>

            <div className="flex flex-col items-start lg:mt-0 lg:max-w-[50%] text-left">
              <Image
                src="site-stone/devCenter/pagarme"
                width={160}
                height={44}
                alt="Logomarca da Pagarme"
              />

              <h4 className="mt-24 font-bold xl:font-semibold heading-5 xl:paragraph-24">
                A integração completa em um único lugar
              </h4>

              <p className="mt-8 paragraph-16 xl:paragraph-20">
                Transações online, pagamentos recorrentes, assinaturas, split de
                pagamentos, gerenciamento de saldo, dentre outras features.
              </p>

              <a
                href="https://docs.pagar.me/docs"
                className="flex items-center mt-16 lg:mt-24 xl:font-normal font-semibold text-stone-500 paragraph-16"
              >
                Ir para documentação{' '}
                <Icon
                  className="mt-2 ml-2 w-16 h-16 fill-current"
                  name="chevron-right"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center xl:mx-7 mt-40">
          <div className="lg:mr-36 mb-16 lg:mb-0 lg:max-w-[180px] text-center lg:text-left">
            <h4 className="mt-24 font-bold lg:font-semibold heading-4 lg:heading-3">
              APIs de Backoffice
            </h4>
            <p className="mt-8 lg:paragraph-20 paragraph-16">
              Complemente e adicione valor à sua operação
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-16 lg:gap-40 max-w-[490px]">
            <div key={'Conciliação'} className="flex">
              <div className="flex flex-col py-11 px-32 bg-display-0 rounded border border-display-300">
                <div className="flex items-center min-h-[57px]">
                  <Conciliacao />
                </div>
                <h6 className="mt-16 font-bold heading-6">Conciliação</h6>
                <p className="mt-8 mb-16">
                  Visualize e monitore as vendas, parcelamentos, cancelamentos e
                  informações financeiras relevantes do negócio de seus
                  clientes.
                </p>
                <a
                  href={'https://conciliacao.stone.com.br/docs'}
                  className="flex items-center mt-16 lg:mt-24 xl:font-normal font-semibold text-stone-500 paragraph-16"
                >
                  Saiba mais{' '}
                  <Icon
                    className="mt-2 ml-2 w-16 h-16 fill-current"
                    name="chevron-right"
                  />
                </a>
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default OnlineWorld;
