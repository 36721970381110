import React from 'react';

import Card from './components/Card';

const casesObject = [
  {
    bannerImage: {
      src: 'site-stone/devCenter/capa-tablet-clound',
      width: 336,
      height: 197,
    },
    logoImage: {
      src: 'site-stone/devCenter/table-cloud',
      width: 208,
      height: 59,
    },
    description: 'A empresa que nasceu da experiência no mercado de PDVs.',
    href: 'https://blog.stone.com.br/conheca-a-solucao-entre-tablet-cloud-stone/',
  },
  {
    bannerImage: {
      src: 'site-stone/devCenter/capa-dontus',
      width: 336,
      height: 197,
    },
    logoImage: {
      src: 'site-stone/devCenter/dontus',
      width: 192,
      height: 54,
    },
    description:
      'Melhoria de controles e eficiência de consultórios e clínicas.',
    href: 'https://blog.stone.com.br/a-parceria-dontus-stone/',
  },
  {
    bannerImage: {
      src: 'site-stone/devCenter/capa-evo',
      width: 336,
      height: 197,
    },
    logoImage: {
      src: 'site-stone/devCenter/evo',
      width: 145,
      height: 54,
    },
    description: 'Tecnologia para melhorar a gestão de academias.',
    href: 'https://blog.stone.com.br/conheca-a-evo-e-sua-solucao-definitiva-para-gestao-de-negocios-fitness/',
  },
];

const SuccessCases: React.FC = () => {
  return (
    <div className="bg-display-100">
      <div className="container flex flex-col items-center py-40 md:py-80 px-24 md:px-40 w-full">
        <h2 className="w-full font-semibold text-center text-display-900 heading-2">
          Cases de sucesso
        </h2>
        <p className="mt-8 w-full max-w-[736px] text-center paragraph-16 lg:paragraph-20">
          Faça como nossos parceiros: construa experiências incríveis e mude a
          vida financeira de seus clientes
        </p>
        <div className="flex flex-wrap gap-32 md:gap-40 justify-center items-stretch mt-32 w-full">
          {casesObject.map(item => (
            <Card {...item} />
          ))}
        </div>
        <a
          href="https://stonepartnerprogram.medium.com/"
          className="mt-24 md:mt-32 text-base leading-4 btn btn-primary btn-large"
        >
          Ver todos os cases
        </a>
      </div>
    </div>
  );
};

export default SuccessCases;
