import React from 'react';

import Community from './components/Community';
import Connections from './components/Connections';
import Facilities from './components/Facilities';
import Hero from './components/Hero';
import OnlineWorld from './components/OnlineWorld';
import Partner from './components/Partner';
import Setup from './components/Setup';
import SuccessCases from './components/SuccessCases';

const DevCenterContainer: React.FC = () => {
  return (
    <div>
      <Hero />
      <Facilities />
      <Connections />
      <OnlineWorld />
      <Setup />
      <SuccessCases />
      <Community />
      <Partner />
    </div>
  );
};

export default DevCenterContainer;
