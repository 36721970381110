import Image from 'next/image';
import React from 'react';

const Hero: React.FC = () => {
  return (
    <div className="bg-display-100">
      <div className="container flex flex-col md:flex-row-reverse">
        <div className="md:min-w-[360px] lg:min-w-[500px]">
          <Image
            src="/site-stone/devCenter/hero"
            width={522}
            height={448}
            alt="Banner da página da Pagarme mostrando códigos e gráficos"
            className="w-full h-auto"
          />
        </div>
        <div className="flex flex-col justify-center mt-24 md:mt-0 md:mr-[64px] xl:mr-[128px] md:min-w-[272px] lg:min-w-[368px] max-w-[630px]">
          <h1 className="font-bold heading-2 lg:heading-1">
            A tecnologia que irá mudar o negócio de seus clientes{' '}
          </h1>
          <p className="mt-8 paragraph-18 lg:paragraph-24">
            Automatize suas rotinas de pagamento e mantenha sua solução sempre à
            frente do mercado.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
