import React from 'react';

const items = [
  {
    title: 'Documentação Aberta',
    description:
      'Desenvolvedores e suas equipes podem explorar e consumir todas as nossas APIs e SDKs para construir seus produtos.',
  },
  {
    title: 'Autonomia no processo',
    description:
      'Integre sem complicações com a autonomia necessária para construir e automatizar as rotinas de pagamento de seus clientes.',
  },
  {
    title: 'Time Técnico',
    description:
      'Conte com o time técnico da Stone para te atender, tirar dúvidas e ajudar a acelerar suas integrações e projetos.',
  },
];

const Facilities: React.FC = () => {
  return (
    <div className="container flex flex-col xl:flex-row justify-center items-center">
      <div className="xl:flex-1 xl:max-w-[248px] text-center xl:text-left">
        <h2 className="font-bold heading-2 xl:heading-3">
          Facilidade nas Integrações
        </h2>
        <p className="mt-8 xl:mt-16 paragraph-16 md:paragraph-18">
          Nunca foi tão prático se conectar com nossa tecnologia financeira
        </p>
      </div>
      <ul className="flex flex-col md:flex-row gap-16 md:gap-24 lg:gap-48 mt-24 xl:mt-0 xl:ml-48">
        {items.map(item => (
          <li
            key={item.title}
            className="p-32 md:max-w-[264px] text-white bg-stone-600 rounded"
          >
            <h5 className="font-semibold text-current heading-5">
              {item.title}
            </h5>
            <p className="mt-8 text-current paragraph-16">{item.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Facilities;
