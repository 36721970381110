import React from 'react';

import ApiConnect from '@/assets/illustrations/devcenter/apiConnect.svg';
import AutoTEF from '@/assets/illustrations/devcenter/autoTEF.svg';
import SdkAndroid from '@/assets/illustrations/devcenter/sdkAndroid.svg';
import Tef from '@/assets/illustrations/devcenter/tef.svg';
import Icon from '@/components/v3/Icon';

const items = [
  {
    children: <ApiConnect />,
    title: 'API Connect Stone',
    description:
      'Conecte seu sistema de uma forma simples, rápida e completa às maquininhas Stone.',
    documentationUrl: 'https://connect-stone.stone.com.br/',
  },
  {
    children: <SdkAndroid />,
    title: 'SDK Android',
    description:
      'Integre sua solução no nosso POS Android ou Pinpad Bluetooth (leitor de cartões mobile).',
    documentationUrl: 'https://sdkandroid.stone.com.br/docs',
  },
  {
    children: <AutoTEF />,
    title: 'AutoTEF',
    description:
      'Integre sua solução auto-atendimento a pagamentos por cartões de débito e crédito utilizando PIN Pad.',
    documentationUrl: 'https://microtefdocs.stone.com.br/docs',
  },
  {
    children: <Tef />,
    title: 'TEF Stone',
    description:
      'Integre sua solução Windows ou Linux ao sistema de pagamentos TEF, ofertando diversas opções de bandeiras.',
    documentationUrl: 'https://tefdoc.stone.com.br/docs/getting-started',
  },
];

const Connections: React.FC = () => {
  return (
    <div className="container">
      <div className="mb-24 sm:mb-32 text-center">
        <h2 className="font-bold heading-2">Mundo Físico</h2>
        <p className="mt-8 paragraph-16 sm:paragraph-20">
          Conecte nos mais diversos tipos de devices com nossa tecnologia de
          pagamentos
        </p>
      </div>
      <div />
      <ul className="flex flex-row flex-wrap gap-16 justify-center">
        {items.map(item => (
          <li
            key={item.title}
            className="flex flex-col p-32 max-w-[288px] rounded border border-display-500"
          >
            <div>{item.children}</div>
            <h5 className="mt-8 font-bold heading-6">{item.title}</h5>
            <p className="mt-4 w-[234px] paragraph-16">{item.description}</p>
            <a
              href={item.documentationUrl}
              className="flex items-center mt-16 font-medium text-stone-500 paragraph-16"
            >
              Leia a documentação{' '}
              <Icon
                className="mt-2 ml-2 w-16 h-16 fill-current"
                name="chevron-right"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Connections;
