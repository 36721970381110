import { CorporateContactJsonLd, NextSeo, SocialProfileJsonLd } from 'next-seo';
import Head from 'next/head';
import React from 'react';

import DevCenterContainer from '@/containers/DevCenter';
import Layout from '@/providers/Layout';

const DevCenterPage: React.FC = () => {
  return (
    <>
      <Head>
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `#widget-open-floating-action-button{display:none!important}`,
          }}
        />
      </Head>
      <NextSeo
        title="DevCenter | Stone"
        description="Acesse a documentação da Stone para automatizar suas rotinas de pagamento e manter sua solução sempre à frente do mercado."
        canonical="https://www.stone.com.br/devcenter/"
        openGraph={{
          type: 'website',
          url: 'https://www.stone.com.br/devcenter/',
          title: 'DevCenter | Stone',
          description:
            'Acesse a documentação da Stone para automatizar suas rotinas de pagamento e manter sua solução sempre à frente do mercado.',
          site_name: 'Stone',
          locale: 'pt_BR',
        }}
      />
      <CorporateContactJsonLd
        url="http://www.stone.com.br/"
        logo="https://www.stone.com.br/public/images/favicon.ico"
        contactPoint={[
          {
            telephone: '3004-9680',
            contactType: 'customer service',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '3004-9681',
            contactType: 'sales',
            areaServed: 'BR',
            availableLanguage: ['Brazilian Portuguese'],
          },
          {
            telephone: '0800 326-0506',
            contactType: 'customer service',
            areaServed: 'BR',
            contactOption: 'TollFree',
            availableLanguage: ['Brazilian Portuguese'],
          },
        ]}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Stone Pagamentos"
        url="http://www.stone.com.br/"
        sameAs={[
          'https://www.facebook.com/stonepagamentos/',
          'https://twitter.com/sejastone',
          'https://www.instagram.com/stone/',
          'https://www.youtube.com/channel/UCk4L4JykgoDkN8YeuxkNqIQ',
          'https://www.linkedin.com/company/stone-pagamentos',
          'https://pt.wikipedia.org/wiki/Stone_Pagamentos',
        ]}
      />
      <Layout layoutName="default">
        <DevCenterContainer />
      </Layout>
    </>
  );
};

export default DevCenterPage;
